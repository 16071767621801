import React from 'react';
import { Box, Heading, Text, List, ListItem } from '@chakra-ui/react';
import Navbar from './Navbar';

const PrivacyPolicy = () => {
    return (
        <Box>
            <Navbar />
            <Box maxW="900px" mx="auto" my="10" px="2">
                <Heading as="h1" mb="4" fontSize="2xl">
                    Privacy Policy for Inflo Mobile Application
                </Heading>
                <Text>
                    <strong>Last Updated:</strong> Jan 01, 2024
                </Text>
                <Text mt="4">
                    <strong>Introduction</strong>
                    <br />
                    Welcome to Inflo! We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines our practices regarding the collection, use, and disclosure of your information through the use of our Inflo mobile application ("App").
                </Text>
                <Text mt="4">
                    <strong>Information Collection and Use</strong>
                    <br />
                    When you use Inflo, we collect the information you choose to share with us, including but not limited to:
                </Text>
                <List styleType="disc" ml="4" mt="2">
                    <ListItem>Contact information (e.g., phone numbers, email addresses)</ListItem>
                    <ListItem>Social media profiles (e.g., LinkedIn, Twitter, Instagram, Facebook)</ListItem>
                    <ListItem>Location details</ListItem>
                    <ListItem>Personal details (e.g., office address, blog address, medication list)</ListItem>
                    <ListItem>Communication identifiers (e.g., WhatsApp/Skype usernames)</ListItem>
                    <ListItem>Health-related information (e.g., primary cardiologist contact)</ListItem>
                    <ListItem>Any other information you choose to share via the App</ListItem>
                </List>
                <Text mt="4">
                    This information is used to provide our services, including allowing your friends to update your address book, pre-sharing your information, and providing a platform for sharing various personal details.
                </Text>
                {/* The rest of your privacy policy content */}
                <Text mt="4">
                    <strong>Data Sharing and Disclosure</strong>
                    <br />
                    Your information will be shared with other users of the App according to the preferences and permissions you set. We do not share your personal information with third parties, except as necessary to provide the App's functionality, comply with laws, or protect our rights.
                </Text>
                <Text mt="4">
                    <strong>Data Security</strong>
                    <br />
                    We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information via the App.
                </Text>
                <Text mt="4">
                    <strong>User Control and Choice</strong>
                    <br />
                    You have control over your information, including the ability to:
                    <List styleType="disc" ml="4" mt="2">
                        <ListItem>Update or correct your information at any time</ListItem>
                        <ListItem>Unshare your information from other users' devices</ListItem>
                        <ListItem>Delete your account and information stored in our database</ListItem>
                    </List>
                </Text>
                <Text mt="4">
                    <strong>Children's Privacy</strong>
                    <br />
                    Inflo is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13.
                </Text>
                <Text mt="4">
                    <strong>Changes to This Privacy Policy</strong>
                    <br />
                    We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
                </Text>
                <Text mt="4">
                    <strong>Contact Us</strong>
                    <br />
                    If you have any questions about this Privacy Policy, please contact us.
                </Text>
                <Text mt="4">
                    <strong>Acknowledgement</strong>
                    <br />
                    By using Inflo, you acknowledge that you have read and understand this Privacy Policy and agree to its terms.
                </Text>
            </Box>
        </Box>
    );
};

export default PrivacyPolicy;
