import React from 'react';
import './App.css'
import {
  ChakraProvider,
  extendTheme,
} from '@chakra-ui/react';
import SmartCard from './components/SmartCard';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Home from './components/Home';
import NotFound from './components/NotFound';
import ProfileView from './components/ProfileView';
import PrivacyPolicy from './components/PrivacyPolicy';
const customTheme = extendTheme({
  fonts: {
    body: "Poppins, sans-serif",
    heading: "Poppins, sans-serif",
    // Add more font styles as needed
  },
});
function App() {
  return (
    <ChakraProvider theme={customTheme}>
      <Router>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/smart-object/:id" exact element={<SmartCard />} />
          <Route path="/profile-view/:id" exact element={<ProfileView />} />
          <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
          {/* Add more routes for other components/pages */}
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
