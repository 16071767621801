// NotFound.js
import React from 'react';
import { Center, Box, Heading, Text } from '@chakra-ui/react';

const NotFound = () => {
    return (
        <Center height="100vh">
            <Box textAlign="center">
                <Heading size="2xl" mb={4}>
                    404 - Not Found
                </Heading>
                <Text fontSize="lg">Sorry, the page you are looking for does not exist.</Text>
            </Box>
        </Center>
    );
};

export default NotFound;
