import React from 'react';
import { Flex, Box, Image, Text } from '@chakra-ui/react';
import Logo from '../assets/InfloLogo.png'

const Navbar = ({ apiData }) => {
    return (
        // <Flex
        //     align="center"
        //     justify="center"  // Change this line to center the content horizontally
        //     padding="1rem"
        //     bgColor="#0B3066"
        //     color="white"
        // >
        //     <Box>
        //         {/* Your logo image */}
        //         <Image src={Logo} alt="Logo" />
        //     </Box>
        //     {/* Add your navigation links or other components here */}
        // </Flex>
        <Flex
            align="center"
            justify={apiData ? 'space-between' : 'center'}  // Changed to space-between to push content to the sides
            padding="1rem"
            bgColor="#0B3066"
            color="white"
        >
            {
                apiData &&
                <Box minW={{ base: '120px', md: '200px' }} display={{ base: 'none', md: 'block' }}>

                </Box>
            }
            <Box>
                {/* Your logo image */}
                <Image src={Logo} alt="Logo" />
            </Box>
            {
                apiData &&
                <Flex align="center" minW={{ base: '120px', md: '200px' }}>
                    <Box mr="1rem"> {/* Margin to separate profile picture from text */}
                        <Image objectFit={'cover'} src={apiData?.profile?.avatar} alt="Profile" borderRadius="full" boxSize="40px" />
                        {/* Assuming a circular profile picture */}
                    </Box>
                    <Box>
                        <Text fontSize='16px' fontWeight="500">{apiData?.profile?.first_name + ' ' + apiData?.profile?.last_name}</Text> {/* Name */}
                        <Text display="block"
                            overflow="hidden"
                            width={{base:'100px',md:'150px'}}
                            whiteSpace="nowrap"
                            textOverflow="ellipsis" fontSize="14px" color={'#989898'}>{apiData?.profile?.headline}</Text> {/* Tag line */}
                    </Box>
                </Flex>
            }
        </Flex>
    );
};

export default Navbar;
