// Loader.js
import React from 'react';
import './Loader.css'; // Add styles for your loader
// import loader from '../../assets/loader.gif'
import Lottie from 'lottie-react';
import animationData from '../../assets/animation.json';
const Loader = () => {
    return (
        <div className="loader-container">
            <div className="">
                {/* <img src={loader} alt='loader.gif' /> */}
                <Lottie animationData={animationData} loop autoplay style={{ maxWidth: 160, maxHeight: 100 }} />
            </div>
        </div>
    );
};

export default Loader;
