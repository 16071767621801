import React, { useEffect } from 'react';
import { Box, Image, Center } from '@chakra-ui/react';
import Logo from '../assets/InfloLogo.png'

const Home = () => {

    useEffect(() => {
        // Get the URL parameters
        const params = new URLSearchParams(window.location.search);

        // Check if the required parameters are present
        const userid = params.get('userid');
        // const email = params.get('email');
        // const accessToken = params.get('accesstoken');

        // If any of the required parameters is missing, redirect
        // if (!userid) {
        //     // Set the new URL
        //     const newUrl = 'https://sso.inflo.io/?st=1&appsecret=732312c3-50a8-4747-b9b3-104266f1a7d2&lo=0';

        //     // Navigate to the new URL
        //     window.location.href = newUrl;
        // }
    }, []); // The empty dependency array ensures that this effect runs only once (equivalent to componentDidMount)

    return (
        <Center height="100vh" bg={'#0B3066'}>
            <Box>
                <Image src={Logo} alt="Logo" />
            </Box>
        </Center>
    );
};

export default Home;
